import { useSubscription } from "@/features/auth/api/getSubscription";
import { ConfirmPlanChangeDialog } from "@/features/settings/components/ConfirmPlanChangeDialog";
import { SubscriptionChangeDialog } from "@/features/settings/components/SubscriptionChangeDialog";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDialogStore } from "../../../stores/upgrade";
import { AiArticleLimitDialog } from "./AiArticleLimitDialog";
import { CommentingUpgradeDialog } from "./CommentingUpgradeDialog";
import { FreeTrialLimitDialog } from "./FreeTrialLimitDialog";
import { PlanLimitDialog } from "./PlanLimitDialog";
import { UpgradeProAddonDialog } from "./UpgradeProAddonDialog";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY as string);

export const UpgradeDialogs = () => {
  const { dialogType, eventDisplayName, closeDialog } = useDialogStore();
  const { data: subscriptionData } = useSubscription({});

  return (
    <>
      {dialogType === "freeTrialLimit" && (
        <FreeTrialLimitDialog
          onClose={closeDialog}
          title="Upgrade your plan"
          text={eventDisplayName}
          isFreeTrial={true}
        />
      )}
      {dialogType === "planLimit" && (
        <PlanLimitDialog
          onClose={closeDialog}
          title="Upgrade your plan"
          text={eventDisplayName}
          isFreeTrial={false}
        />
      )}
      {dialogType === "aiArticleLimit" && (
        <AiArticleLimitDialog
          onClose={closeDialog}
          title="AI article generation limit reached"
          text={eventDisplayName}
          isFreeTrial={false}
        />
      )}
      {dialogType === "aiArticleUpgrade" && (
        <UpgradeProAddonDialog onClose={closeDialog} />
      )}
      {dialogType === "aiArticleConfirmUpgrade" && (
        <Elements stripe={stripePromise}>
          <ConfirmPlanChangeDialog
            title={"Upgrade to Pro add-on"}
            actionType="upgrade"
            triggerType="dialog"
            onClose={closeDialog}
            upgradeProAddOn={true}
          />
        </Elements>
      )}
      {dialogType === "upgradeProAddon" && (
        <Elements stripe={stripePromise}>
          <SubscriptionChangeDialog
            isOpen={true}
            close={closeDialog}
            open={() => {}}
            title="AI Article Wizard"
            description={
              <div className="space-y-2">
                <p>
                  Generate full articles with a step-by-step process. Use search
                  results, or provide your own information.
                </p>
                <p>
                  Your account will be upgraded immediately to the Pro add-on
                  and we will charge the amount to your existing payment method.
                </p>
                <div className="flex items-center justify-between py-2 px-3 bg-zinc-50 dark:bg-zinc-800 rounded-md border border-zinc-200 dark:border-zinc-700">
                  <span className="font-medium">Pro add-on</span>
                  <span className="text-zinc-600 dark:text-zinc-400">
                    {subscriptionData?.billing_cycle === "year"
                      ? "$357/year"
                      : "$35/month"}
                  </span>
                </div>
              </div>
            }
            confirmButtonText="Upgrade to Pro add-on"
            plan={{ stripeName: subscriptionData?.plan || "" }}
            hasAddOn={true}
            pricingPeriod={
              subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly"
            }
          />
        </Elements>
      )}
      {dialogType === "commentingUpgrade" && (
        <CommentingUpgradeDialog onClose={closeDialog} />
      )}
    </>
  );
};
