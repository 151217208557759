import { Button, Form, Label } from "@/components/Elements";
import { useTrackEvent } from "@/features/analytics/api/trackUser";
import { useGoogleAnalytics } from "@/features/analytics/hooks/useGoogleAnalytics";
import { getSubscription } from "@/features/auth/api/getSubscription";
import { useCreateUserSubscription } from "@/features/settings/api/createUserSubscription";
import { Coupon } from "@/features/subscription/api/getCouponStatus";
import { DialogClose } from "@radix-ui/react-dialog";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { TbInfoCircle } from "react-icons/tb";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  CustomerInfoFields,
  PriceBreakdown,
  PromoCodeSection,
} from "../routes/Checkout";

interface CheckoutFormProps {
  className?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  onError?: (error: { message: string; type?: string; code?: string }) => void;
  clientSecret: string;
  planDetails: any;
  coupon: any;
  pricingPeriod: string;
  actionType: string;
  control: any;
  showPromoInput: boolean;
  setShowPromoInput: (show: boolean) => void;
  isValidatingCoupon: boolean;
  setPendingCouponValidation: (pending: boolean) => void;
  handleCouponValidation: (couponId: string) => void;
  pendingCouponValidation: boolean;
  form: any;
  upgradeProAddOn: boolean;
  setCoupon: (coupon: Coupon | null) => void;
}

export const CheckoutForm = ({
  className,
  onSuccess,
  onCancel,
  onError,
  planDetails,
  coupon,
  pricingPeriod,
  actionType,
  control,
  showPromoInput,
  setShowPromoInput,
  isValidatingCoupon,
  setPendingCouponValidation,
  handleCouponValidation,
  pendingCouponValidation,
  form,
  upgradeProAddOn,
  setCoupon,
}: CheckoutFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const createSubscriptionMutation = useCreateUserSubscription();
  const trackEvent = useTrackEvent();
  const { trackPurchase } = useGoogleAnalytics();

  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [includeAddOn, setIncludeAddOn] = useState(true); // Default to true

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const checkSubscriptionStatus = async () => {
    try {
      const data = await getSubscription();
      // Check both active status and plan change
      return (
        data?.status === "active" && planDetails.stripeName.includes(data.plan)
      );
    } catch (err) {
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setIsProcessing(true);

    try {
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setMessage(submitError.message || "An error occurred");
        onError?.({
          message: submitError.message || "An error occurred",
          type: submitError.type,
        });
        return;
      }

      // Create the plans array
      const plans = [{ plan: planDetails.stripeName }];

      // Add Pro Add-on if selected (default is true)
      if (includeAddOn) {
        plans.push({
          plan: pricingPeriod === "monthly" ? "addon" : "addon_yearly_357",
          quantity: 1,
        });
      }

      // Call appropriate mutation based on action type
      let data;
      data = await createSubscriptionMutation.mutateAsync({
        plans,
        coupon: coupon?.id,
      });

      if (!data) {
        setMessage("Failed to create subscription");
        onError?.({
          message: "Failed to create subscription",
        });
        return;
      }

      // Confirm the payment or setup intent based on the type
      const confirmIntent =
        data.type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;
      const { error: confirmError, paymentIntent } = await confirmIntent({
        elements,
        clientSecret: data.clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/app/settings/subscription`,
        },
        redirect: "if_required",
      });

      if (confirmError) {
        setMessage(confirmError.message || "An error occurred");
        onError?.({
          message: confirmError.message || "An error occurred",
          type: confirmError.type,
        });
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        // Poll for subscription update and plan change
        let attempts = 0;
        const maxAttempts = 20; // 10 seconds total

        while (attempts < maxAttempts) {
          const isUpdated = await checkSubscriptionStatus();
          if (isUpdated) {
            queryClient.invalidateQueries({
              queryKey: ["subscription_info"],
              exact: true,
            });
            queryClient.invalidateQueries({
              queryKey: ["upcomingInvoice"],
              exact: true,
            });
            await trackEvent.mutate({
              event: "new_customer",
              properties: JSON.stringify({}),
            });
            trackPurchase(planDetails.stripeName);
            navigate("/app/settings/subscription");
            onSuccess?.();
            return;
          }
          await new Promise((resolve) => setTimeout(resolve, 500));
          attempts++;
        }

        setMessage(
          "Payment processed but plan upgrade is taking longer than expected. Please refresh the page in a few moments."
        );
        onError?.({
          message: "Plan upgrade timeout",
        });
      } else {
        setMessage("An unexpected error occurred.");
        onError?.({
          message: "Payment failed. Please try again.",
        });
      }
    } catch (err) {
      onError?.({
        message: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setIsLoading(false);
      setIsProcessing(false);
    }
  };

  return (
    <div className={className}>
      <Form {...form}>
        <form onSubmit={handleSubmit}>
          <div className="max-h-[calc(100vh-20rem)] space-y-4 bg-zinc-100 dark:bg-zinc-900 px-4 py-4 border-t border-b dark:border-zinc-700 overflow-y-auto">
            <CustomerInfoFields control={control} />

            <div>
              <Label>Payment details</Label>
              <div className="mt-2">
                <div className="rounded-md border bg-white dark:border-zinc-700 p-4">
                  <PaymentElement
                    id="payment-element"
                    onChange={() => setMessage(null)}
                    options={{
                      layout: "tabs",
                      defaultCollapsed: false,
                      radios: true,
                      spacedAccordionItems: false,
                    }}
                  />
                </div>

                {message && (
                  <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/20 text-red-700 dark:text-red-400 text-xs font-medium rounded-md border border-red-200 dark:border-red-900">
                    <TbInfoCircle className="inline-block mr-2" />
                    {message}
                  </div>
                )}
              </div>
            </div>

            <PromoCodeSection
              control={control}
              showPromoInput={showPromoInput}
              setShowPromoInput={setShowPromoInput}
              isValidatingCoupon={isValidatingCoupon}
              setPendingCouponValidation={setPendingCouponValidation}
              handleCouponValidation={handleCouponValidation}
              pendingCouponValidation={pendingCouponValidation}
              coupon={coupon}
              form={form}
              setCoupon={setCoupon}
            />
          </div>

          <div className="flex-none">
            <PriceBreakdown
              planDetails={planDetails}
              coupon={coupon}
              pricingPeriod={pricingPeriod}
              actionType={actionType}
              upgradeProAddOn={upgradeProAddOn}
              includeAddOn={includeAddOn}
              setIncludeAddOn={setIncludeAddOn}
            />

            <div className="px-4 py-4 flex w-full sm:justify-between border-t dark:border-zinc-700">
              <DialogClose asChild>
                <Button variant="outlineBlur" onClick={onCancel}>
                  Cancel
                </Button>
              </DialogClose>
              <Button
                variant="primaryBlur"
                type="submit"
                disabled={!stripe || isLoading || isProcessing}
                isLoading={isLoading || isProcessing}
              >
                {isLoading || isProcessing
                  ? "Processing..."
                  : "Confirm " + actionType}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};
