import { ContentLayout } from "@/components/Layout";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { Fragment, useState } from "react";
import { TbCheck, TbInfoCircle, TbMinus } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Tag,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/Elements";
import { Skeleton } from "../../../components/Elements/Skeleton/Skeleton";
import { cn } from "../../../utils/style";
import { useSubscription } from "../../auth/api/getSubscription";
import { getPlanNickname } from "../../subscription/utils/getPlanNickname";
import { getSectionsByPlanName } from "../../subscription/utils/sections";
import {
  isLegacyPlan,
  legacyTiers,
  tiers,
} from "../../subscription/utils/tiers";
import { SubscriptionChangeDialog } from "../components/SubscriptionChangeDialog";
import { Checkout } from "./Checkout";

const PlanSkeleton = () => {
  return (
    <div className="w-full">
      <div className="relative -mx-8">
        <table className="w-full border-separate border-spacing-x-8 text-left table-fixed">
          <colgroup>
            <col className="w-1/5" />
            <col className="w-1/5" />
            <col className="w-1/5" />
            <col className="w-1/5" />
            <col className="w-1/5" />
          </colgroup>
          <thead>
            <tr>
              <td className="pt-4 px-6">
                <Skeleton className="h-10 w-32" />
              </td>
              {[...Array(4)].map((_, i) => (
                <th key={i} scope="col" className="text-left pt-4 px-6">
                  <div className="flex items-center justify-between w-full">
                    <Skeleton className="h-7 w-24" />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="text-left align-middle px-6">
                <span className="sr-only">Price</span>
              </th>
              {[...Array(4)].map((_, i) => (
                <td key={i} className="text-left align-middle pt-2 px-6">
                  <div className="mb-4">
                    <Skeleton className="h-8 w-32" />
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              <th scope="row" className="text-left align-middle pt-2 px-6">
                <span className="sr-only">Actions</span>
              </th>
              {[...Array(4)].map((_, i) => (
                <td key={i} className="text-left align-top px-6">
                  <Skeleton className="h-10 w-full" />
                </td>
              ))}
            </tr>
            {[...Array(3)].map((_, sectionIdx) => (
              <Fragment key={sectionIdx}>
                <tr>
                  <th
                    scope="colgroup"
                    colSpan={5}
                    className={cn(
                      sectionIdx === 0 ? "pt-4" : "pt-6",
                      "pb-2 text-medium font-semibold leading-6"
                    )}
                  >
                    <Skeleton className="h-6 w-40" />
                    <div className="absolute inset-x-8 mt-2 h-px bg-zinc-900/10 dark:bg-white/10" />
                  </th>
                </tr>
                {[...Array(4)].map((_, featureIdx) => (
                  <tr key={featureIdx}>
                    <th scope="row" className="py-2.5 whitespace-nowrap">
                      <div className="flex items-center">
                        <Skeleton className="h-5 w-32" />
                      </div>
                      <div className="absolute inset-x-8 mt-2.5 h-px bg-zinc-900/5 dark:bg-white/5" />
                    </th>
                    {[...Array(4)].map((_, tierIdx) => (
                      <td
                        key={tierIdx}
                        className="text-left align-middle px-6 py-2.5 xl:px-8 whitespace-nowrap"
                      >
                        <Skeleton className="h-5 w-5" />
                      </td>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const Plans = () => {
  const { data: subscriptionData, isLoading } = useSubscription({});
  const navigate = useNavigate();

  const currentPlanName = getPlanNickname(
    subscriptionData?.plan || "freeTrial"
  );
  const hasAddOn = subscriptionData?.add_on ? true : false;

  // Get the appropriate sections based on the plan
  const planSections = getSectionsByPlanName(subscriptionData?.plan || "");

  // Only show legacy tier if user is on that specific legacy tier
  const isLegacySolo =
    isLegacyPlan(subscriptionData?.plan || "") &&
    ["solo", "solo_yearly", "solo_monthly_4", "solo_yearly_4"].includes(
      subscriptionData?.plan || ""
    ); // Legacy solo plans
  const isLegacyTeam =
    isLegacyPlan(subscriptionData?.plan || "") &&
    ["team_monthly_115", "team_yearly_115"].includes(
      subscriptionData?.plan || ""
    ); // Legacy team plans

  const displayedTiers = [
    tiers.find((tier) => tier.name === "Basic" && tier.display),
    isLegacyTeam
      ? legacyTiers.find((tier) => tier.name === "Team")
      : tiers.find((tier) => tier.name === "Team" && tier.display),
    tiers.find((tier) => tier.name === "Enterprise" && tier.display),
  ].filter(Boolean);

  const isSubscriptionInactive = subscriptionData?.status === "inactive";
  const isSubscriptionCancelled =
    subscriptionData?.status === "canceled" ||
    subscriptionData?.status === "active_canceled";
  const isSubscriptionPaused = subscriptionData?.status === "paused";

  // Redirect to subscription page if subscription is paused
  if (isSubscriptionPaused) {
    navigate("/app/settings/subscription");
    return null;
  }

  const [pricingPeriod, setPricingPeriod] = useState<"monthly" | "yearly">(
    subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly"
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTier, setSelectedTier] = useState<{
    tier: (typeof tiers)[number];
    isUpgrade: boolean;
    title: string;
    description: string;
    confirmButtonText: string;
    plan: string;
    pricingPeriod: "monthly" | "yearly";
  } | null>(null);

  const currentTierOrder =
    tiers.find((t) => t.name === currentPlanName)?.order || 0;

  const renderActionButton = (tier: (typeof tiers)[number]) => {
    // Handle Enterprise tier
    if (tier.name === "Enterprise") {
      return (
        <Button
          onClick={() =>
            window.open(
              "https://www.frase.io/contact-us/",
              "_blank",
              "noopener,noreferrer"
            )
          }
          className="w-full"
          size="xs"
        >
          Contact Us
        </Button>
      );
    }

    // Handle inactive or cancelled subscriptions
    if (isSubscriptionInactive || isSubscriptionCancelled) {
      return (
        <Checkout
          planDetails={{
            name: tier.name,
            stripeName:
              pricingPeriod === "monthly"
                ? tier.monthly.stripeName
                : tier.yearly.stripeName,
            title: `${tier.name} Plan`,
            price:
              pricingPeriod === "monthly"
                ? tier.monthly.price
                : tier.yearly.price,
          }}
          actionType={
            isSubscriptionInactive && !subscriptionData?.subscription_start_date
              ? "upgrade"
              : "resubscribe"
          }
          pricingPeriod={pricingPeriod}
        />
      );
    }

    // Handle current tier with billing cycle change option
    if (
      tier.order === currentTierOrder &&
      ((pricingPeriod === "monthly" &&
        subscriptionData?.billing_cycle !== "year") ||
        (pricingPeriod === "yearly" &&
          subscriptionData?.billing_cycle === "year"))
    ) {
      const targetTier = tier
        ? {
            ...tier,
            stripeName:
              pricingPeriod === "monthly"
                ? tier.yearly.stripeName
                : tier.monthly.stripeName,
            price:
              pricingPeriod === "monthly"
                ? tier.yearly.price
                : tier.monthly.price,
          }
        : null;

      return (
        <div className="flex-col space-y-2">
          <Button
            variant="outlineBlur"
            className="w-full"
            size="xs"
            onClick={() => navigate("/app/settings/subscription")}
          >
            Manage plan
          </Button>
          {((pricingPeriod === "monthly" &&
            subscriptionData?.billing_cycle !== "year") ||
            (pricingPeriod === "yearly" &&
              subscriptionData?.billing_cycle === "year")) && (
            <Button
              variant="outlineBlur"
              className="w-full"
              size="xs"
              onClick={() => {
                const isMonthlyToYearly = pricingPeriod === "monthly";
                setSelectedTier({
                  tier: targetTier,
                  isUpgrade: false,
                  title: "Change the billing period?",
                  description: `Your account's billing period will be changed from ${
                    isMonthlyToYearly
                      ? "monthly to yearly"
                      : "yearly to monthly"
                  } immediately.`,
                  confirmButtonText: "Change billing period",
                  plan:
                    pricingPeriod === "yearly"
                      ? targetTier?.monthly.stripeName
                      : targetTier?.yearly.stripeName || "",
                  pricingPeriod:
                    pricingPeriod === "yearly" ? "monthly" : "yearly",
                });
                setIsDialogOpen(true);
              }}
            >
              Switch to {pricingPeriod === "monthly" ? "yearly" : "monthly"}
            </Button>
          )}
        </div>
      );
    }

    // Handle downgrades
    if (tier.order < currentTierOrder) {
      return (
        <Button
          variant="outlineBlur"
          className="w-full"
          size="xs"
          onClick={() => {
            setSelectedTier({
              tier,
              isUpgrade: false,
              title: "Are you sure you want to downgrade your plan?",
              description: `Your current plan will be downgraded to ${
                tier.name
              } ${pricingPeriod === "monthly" ? "Monthly" : "Yearly"}
              immediately.\n\nYou will lose access to ${currentPlanName} features after confirmation.`,
              confirmButtonText: "Continue",
              plan:
                pricingPeriod === "monthly"
                  ? tier.monthly.stripeName
                  : tier.yearly.stripeName,
              pricingPeriod,
            });
            setIsDialogOpen(true);
          }}
        >
          Downgrade
        </Button>
      );
    }

    // Default case - new subscription
    return (
      <Button
        variant="primary"
        className="w-full"
        size="xs"
        onClick={() => {
          setSelectedTier({
            tier,
            isUpgrade: true,
            title: `Upgrade to ${tier.name} ${
              pricingPeriod === "monthly" ? "Monthly" : "Yearly"
            }`,
            description: `Your account will be upgraded immediately to the ${
              tier.name
            }  ${
              pricingPeriod === "monthly" ? "Monthly" : "Yearly"
            } plan, and we will charge the price difference to your existing payment method.`,
            confirmButtonText: "Upgrade",
            plan:
              pricingPeriod === "monthly"
                ? tier.monthly.stripeName
                : tier.yearly.stripeName,
            pricingPeriod,
          });
          setIsDialogOpen(true);
        }}
      >
        Upgrade
      </Button>
    );
  };

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Plans</h2>
          <div className="isolate h-full w-[1100px]">
            {isLoading ? (
              <div className="py-8">
                <PlanSkeleton />
              </div>
            ) : (
              <div className="relative -mx-8">
                {displayedTiers.some(
                  (tier) =>
                    currentPlanName === tier.name &&
                    ((subscriptionData?.billing_cycle === "year" &&
                      pricingPeriod === "yearly") ||
                      (subscriptionData?.billing_cycle !== "year" &&
                        pricingPeriod === "monthly"))
                ) ? (
                  <div className="absolute inset-x-0 inset-y-0 -z-10 flex">
                    <div
                      className="flex w-[275px] px-4 relative"
                      style={{
                        marginLeft: `${
                          (displayedTiers.findIndex(
                            (tier) => tier.name === currentPlanName
                          ) +
                            1) *
                          283
                        }px`,
                      }}
                      aria-hidden="true"
                    >
                      <div className="w-full rounded-t-xl border-x border-t border-zinc-900/10 bg-zinc-400/5" />
                    </div>
                  </div>
                ) : tiers.find((tier) => tier.name === "Team") ? (
                  <div className="absolute inset-x-0 inset-y-0 -z-10 flex">
                    <div
                      className="flex w-[275px] px-4 relative"
                      style={{
                        marginLeft: `${
                          (displayedTiers.findIndex(
                            (tier) => tier.name === "Team"
                          ) +
                            1) *
                          283
                        }px`,
                      }}
                      aria-hidden="true"
                    >
                      <div className="w-full rounded-t-xl border-x border-t border-emerald-600/30 bg-upgrade-gradient-linear" />
                      <Tag
                        variant="small"
                        className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full mt-3 whitespace-nowrap bg-emerald-50 z-10"
                        textClassName="font-medium"
                        color="emerald"
                      >
                        Most popular
                      </Tag>
                    </div>
                  </div>
                ) : null}
                <table className="w-full border-separate border-spacing-x-8 text-left table-fixed">
                  <caption className="sr-only">Pricing plan comparison</caption>
                  <colgroup className="table-fixed">
                    <col className="w-1/4" />
                    <col className="w-1/4" />
                    <col className="w-1/4" />
                    <col className="w-1/4" />
                  </colgroup>
                  <thead>
                    <tr>
                      <td>
                        {!isSubscriptionCancelled && (
                          <div
                            className="flex bg-zinc-100 w-fit p-1 rounded-md mt-6 dark:bg-zinc-800"
                            aria-label="Billing cycle"
                          >
                            <Button
                              variant={
                                pricingPeriod === "monthly"
                                  ? "outlineBlur"
                                  : "text"
                              }
                              onClick={() => setPricingPeriod("monthly")}
                              aria-label="Monthly pricing"
                              size="2xs"
                            >
                              Monthly
                            </Button>
                            <Button
                              variant={
                                pricingPeriod === "yearly"
                                  ? "outlineBlur"
                                  : "text"
                              }
                              onClick={() => setPricingPeriod("yearly")}
                              aria-label="Yearly pricing"
                              size="2xs"
                            >
                              Yearly
                            </Button>
                          </div>
                        )}
                      </td>
                      {displayedTiers.map((tier) => (
                        <th
                          key={tier.id}
                          scope="col"
                          className="text-left pt-4 px-6"
                        >
                          <div className="flex items-center justify-between w-full">
                            <p className="text-lg text-zinc-900 dark:text-white font-semibold w-fit">
                              {tier.name}
                            </p>
                            {currentPlanName === tier.name &&
                              ((subscriptionData?.billing_cycle === "year" &&
                                pricingPeriod === "yearly") ||
                                (subscriptionData?.billing_cycle !== "year" &&
                                  pricingPeriod === "monthly")) && (
                                <Tag
                                  variant="small"
                                  className="whitespace-nowrap bg-zinc-50 z-10 h-6"
                                  textClassName="font-medium"
                                  color="zinc"
                                >
                                  Current plan
                                </Tag>
                              )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" className="text-left align-middle px-6">
                        <span className="sr-only">Price</span>
                      </th>
                      {displayedTiers.map((tier) => (
                        <td
                          key={tier.id}
                          className="text-left align-middle pt-2 px-6"
                        >
                          <div className="items-baseline gap-x-1 text-zinc-900 dark:text-white mb-4 whitespace-nowrap">
                            <span className="text-2xl font-bold">
                              <span className="text-2xl font-semibold">
                                {tier.name === "Enterprise"
                                  ? "Custom Pricing"
                                  : isLegacyPlan(
                                      subscriptionData?.plan || ""
                                    ) &&
                                    ((tier.name === "Solo" && isLegacySolo) ||
                                      (tier.name === "Team" && isLegacyTeam))
                                  ? pricingPeriod === "monthly"
                                    ? legacyTiers.find(
                                        (t) => t.name === tier.name
                                      )?.monthly.priceDisplay
                                    : legacyTiers.find(
                                        (t) => t.name === tier.name
                                      )?.yearly.priceDisplay
                                  : pricingPeriod === "monthly"
                                  ? tier.monthly.priceDisplay
                                  : tier.yearly.priceDisplay}
                              </span>

                              {tier.name !== "Enterprise" && (
                                <span className="text-base font-semibold leading-6 text-zinc-900 dark:text-white">
                                  {pricingPeriod === "monthly" ? "/mo" : "/yr"}
                                </span>
                              )}
                            </span>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="text-left align-middle pt-2 px-6"
                      >
                        <span className="sr-only">Actions</span>
                      </th>
                      {displayedTiers.map((tier) => (
                        <td key={tier.id} className="text-left align-top px-6">
                          {renderActionButton(tier)}
                        </td>
                      ))}
                    </tr>
                    {planSections.map((section, sectionIdx) => (
                      <Fragment key={section.name}>
                        <tr>
                          <th
                            scope="colgroup"
                            colSpan={5}
                            className={cn(
                              sectionIdx === 0 ? "pt-4" : "pt-6",
                              "pb-2 text-medium font-semibold leading-6 text-zinc-900 dark:text-white"
                            )}
                          >
                            {section.name}
                            <div className="absolute inset-x-8 mt-2 h-px bg-zinc-900/10 dark:bg-white/10" />
                          </th>
                        </tr>
                        {section.features.map((feature) => (
                          <tr key={feature.name}>
                            <th
                              scope="row"
                              className="py-2.5 text-sm font-normal leading-6 text-zinc-900 dark:text-white"
                            >
                              <div className="flex items-center break-words w-[200px]">
                                {feature.name}
                                <TooltipProvider>
                                  <Tooltip>
                                    <TooltipTrigger asChild>
                                      <span className="inline-flex ml-2">
                                        <TbInfoCircle aria-label="More info" />
                                      </span>
                                    </TooltipTrigger>
                                    <TooltipPortal>
                                      <TooltipContent className="z-[10000]">
                                        {feature.tooltip}
                                      </TooltipContent>
                                    </TooltipPortal>
                                  </Tooltip>
                                </TooltipProvider>
                              </div>
                              <div className="absolute inset-x-8 mt-2.5 h-px bg-zinc-900/5 dark:bg-white/5" />
                            </th>
                            {displayedTiers.map((tier) => (
                              <td key={tier.id} className="items-center px-6">
                                {typeof feature.tiers[tier.name] ===
                                "string" ? (
                                  <div className="text-left h-full text-sm text-zinc-900 dark:text-white whitespace-normal break-words w-[200px]">
                                    {feature.tiers[tier.name]}
                                  </div>
                                ) : (
                                  <>
                                    {feature.tiers[tier.name] === true ? (
                                      <TbCheck
                                        className="h-5 w-5 text-emerald-600"
                                        aria-hidden="true"
                                        style={{
                                          strokeWidth: 2.5,
                                        }}
                                      />
                                    ) : (
                                      <TbMinus
                                        className="h-5 w-5 text-zinc-400 dark:text-white"
                                        aria-hidden="true"
                                      />
                                    )}

                                    <span className="sr-only">
                                      {feature.tiers[tier.name] === true
                                        ? "Included"
                                        : "Not included"}{" "}
                                      in {tier.name}
                                    </span>
                                  </>
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedTier && (
        <SubscriptionChangeDialog
          isOpen={isDialogOpen}
          close={() => setIsDialogOpen(false)}
          open={() => setIsDialogOpen(true)}
          title={selectedTier?.title || ""}
          description={selectedTier?.description || ""}
          confirmButtonText={selectedTier?.confirmButtonText || ""}
          plan={{
            stripeName: selectedTier?.plan || "",
            displayName: selectedTier?.tier?.name || "",
          }}
          hasAddOn={hasAddOn}
          pricingPeriod={selectedTier?.pricingPeriod || "monthly"}
        />
      )}
    </ContentLayout>
  );
};
